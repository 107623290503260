import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ласкаво просимо до Visionary Pathways
			</title>
			<meta name={"description"} content={"Створіть майбутнє свого бренду вже сьогодні"} />
			<meta property={"og:title"} content={"Vítejte ve Visionary Pathways"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Visionary Pathways"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header2>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"
					object-fit="contain"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="100%"
					max-width="100%"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13%3A02%3A43.055Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ласкаво просимо до Visionary Pathways!
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Дякуємо, що долучилися до сфери, де креативність поєднується зі стратегією для збільшення присутності вашого бренду. У Visionary Pathways ми пишаємося тим, що є архітекторами майбутнього вашого бренду, гарантуючи, що кожна кампанія - це шедевр, створений спеціально для вас. Ми прагнемо не лише виправдати очікування, але й перевершити їх, щоб зробити подорож вашого бренду з нами по-справжньому незабутньою.
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Наші послуги
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Наше бачення
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					В основі кожного культового бренду лежить історія, яка чекає на розповідь. Visionary Pathways - це місце, де історія вашого бренду отримає голос. Наше унікальне поєднання креативності, інновацій та стратегічного мислення гарантує, що ваш бренд привертає увагу та залучає людей. Ми не просто створюємо кампанії - ми створюємо спадщину.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Переваги партнерства з нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Індивідуальний креатив: ваш бренд унікальний, і наші індивідуальні креативні рішення покликані підкреслити цю унікальність.
					<br />
					<br />
					{"\n\n"}Перспективна стратегія: ми випереджаємо тенденції, щоб ваш бренд не лише не відставав, а й випереджав їх.{"\n"}
					<br />
					<br />
					{"\n"}Вражаючі результати: ми зосереджуємося на відчутних результатах, від підвищення впізнаваності бренду до покращення залученості.
					<br />
					<br />
					{"\n\n"}Віддана команда: Наша команда не просто досвідчена - вона пристрасно вболіває за успіх вашого бренду.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				align-self="center"
				height="100%"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13:02:43.062Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-2.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" />
		</Components.Cta>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});